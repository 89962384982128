import { ConnectStatus } from '../../../constants';
import NonTransactionalIntent from '../../../constants/nonTransactionalIntents';
import TransactionIntent from '../../../constants/transactionIntents';
import type scDK from '../../../sdk/main';
import { Transaction } from '../../../types/transaction';
import { filterValidBrokers, getFeature, to } from '../../../utils';
import { GatewayError } from '../../../utils/customErrorTypes';
import { apiErrorCodeMap } from '../../../utils/errorMap';
import { store } from '../../../utils/userStore';
import {
  getAuthDataFromTransaction,
  handleMarketClosedErrors,
  openBrokerChooser,
  throwError,
  updateTransaction,
} from '../transactionHelpers';

type GetBrokerArgs = {
  brokers: string[];
  leprechaun: boolean;
  transactionId?: string;
  intent?: TransactionIntent | NonTransactionalIntent;
  txnConfig?: Record<string, string>;
  orderConfig?: Record<string, string>;
  transaction?: Transaction;
  skipLogin?: boolean;
};
async function getBroker(
  this: scDK,
  {
    transactionId,
    brokers,
    leprechaun,
    intent,
    txnConfig = {},
    orderConfig = {},
    skipLogin = false,
    transaction,
  }: GetBrokerArgs,
) {
  if (store.status === ConnectStatus.CONNECTED) {
    // return broker only if market is open to do the transaction
    if (intent === TransactionIntent.TRANSACTION) {
      await handleMarketClosedErrors(transactionId, store.broker, orderConfig);
    }
    to(updateTransaction({ broker: store.broker, transactionId }));
    return store.broker;
  }
  // if not connected, initiate broker login
  const feature = getFeature(intent, orderConfig);
  const validBrokers = filterValidBrokers(
    brokers,
    store.featureBrokerList[feature] || [],
  );
  if (validBrokers.length === 0) {
    await throwError({
      markTransaction: true,
      transactionId,
      errorObj: apiErrorCodeMap[1008],
    });
  }
  if (validBrokers.length === 1) {
    // Single broker is required to show
    // In that case, don't show broker chooser instead simply show click to continue.
    const [broker] = validBrokers;
    to(updateTransaction({ broker, transactionId, consent: false }));
    return broker;
  }

  // Wait till broker to login is chosen from middle frame page
  const brokerChooser = await openBrokerChooser({
    shouldClose: false,
    brokersToshow: [...validBrokers],
    leprechaun,
    transactionId,
    intent,
    orderConfig,
    recentBrokers: store.recentBrokers,
    recentIntents: store.recentIntents,
    txnConfig,
    transaction,
  });
  const { broker, updateConsent } = brokerChooser;
  to(updateTransaction({ broker, transactionId, consent: updateConsent }));

  // if login is performed to do transaction, exit early if market closed
  if (intent === TransactionIntent.TRANSACTION) {
    await handleMarketClosedErrors(transactionId, broker, orderConfig);
  }

  // save the selected broker in local storage
  store.addRecentBroker(broker);

  const [loginError] = await to(this.login({ intent, broker, skipLogin }));

  if (loginError) {
    await throwError({
      markTransaction: true,
      transactionId,
      errorObj:
        loginError instanceof GatewayError
          ? apiErrorCodeMap[loginError.code]
          : apiErrorCodeMap[2000],
      data: getAuthDataFromTransaction(transaction, broker),
    });
  }

  // after successful login, save the intent in local storage
  store.addRecentIntents(intent);

  return broker;
}

export default getBroker;
